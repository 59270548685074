<template>
  <div class="content-box">
    <div class="banner-box" v-if="projectInfo.h5_banner">
      <img :src="projectInfo.h5_banner" />
    </div>
    <div class="form-box">
      <!-- 无注册表单 -->
      <div class="custom-box">
        <form-create v-model="fapi" :rule="rule" :option="option"></form-create>
      </div>

      <!-- 缴费选择 -->
      <div
        class="cost-box"
        v-if="!(formInfo.verify_type == 3) && formInfo.verify_type != 4"
      >
        <div
          v-for="(item, index) in item_json"
          :key="index"
          style="margin-bottom: 22px"
        >
          <!-- 自定义缴费 -->
          <div v-if="item.mast_status == 3">
            <div class="input-box">
              <div class="text">{{ item.item_name }}：</div>
              <el-input
                v-model="item.value"
                placeholder="请输入区间金额"
                @input="totalSum"
              >
                <template slot="append">元</template>
              </el-input>
            </div>
            <div class="remark-box">
              区间：{{ item.options[0].value[0] }}~{{
                item.options[0].value[1]
              }}元
            </div>
          </div>
          <!-- 2 -->
          <div v-if="item.mast_status == 2">
            <div class="input-box">
              <div class="text">{{ item.item_name }}：</div>
              <el-select
                v-model="item.temporary_value"
                placeholder="请选择缴费信息"
                @change="(val) => handleChangeFee(val, item)"
              >
                <el-option
                  v-for="(itemfee, indexfee) in item.options"
                  :key="`${itemfee.value}_${indexfee}`"
                  :label="itemfee.remark + '：' + itemfee.value + '元'"
                  :value="`${itemfee.value}_${indexfee}`"
                >
                  <span style="float: left">{{ itemfee.remark }}</span>
                  <span style="float: right; color: #8492a6; font-size: 13px"
                    >{{ itemfee.value }}元</span
                  >
                </el-option>
              </el-select>
            </div>
          </div>
          <div v-if="item.mast_status == 1">
            <div class="input-box">
              <div class="text">{{ item.item_name }}：</div>
              <el-input
                :value="item.options[0]['value'] + '元'"
                disabled
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <div
        class="cost-box"
        v-if="!(formInfo.verify_type == 3) && formInfo.verify_type == 4"
      >
        <div class="checkCode">
          <el-form
            label-position="top"
            label-width="80px"
            :model="checkCodeForm"
            :rules="checkCodeRules"
          >
            <el-form-item label="信息码" prop="codeValue">
              <el-row>
                <el-col :span="20">
                  <el-input
                    v-model="checkCodeForm.codeValue"
                    placeholder="请输入信息码进行查询"
                  ></el-input>
                </el-col>
                <el-col :span="2" style="margin-left: 10px">
                  <el-button type="text" @click="checkCodeFn">查询</el-button>
                </el-col>
              </el-row>
              <el-row>
                <div style="color: red" v-if="errorMes">{{ errorMes }}</div>
              </el-row>
              <el-row>
                <div
                  style="font-size: 16px; margin-top: 5px; line-height: 20px"
                  v-for="item in getCurrentCodeInfo"
                  :key="item.id"
                >
                  {{ item.fee_item_name }} : {{ item.fee }} 元
                </div>
              </el-row>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="sub-box">
        <van-button type="info" block @click="onSubmit"
          >提交{{ total_amount != 0 ? total_amount + "元" : "" }}</van-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import OSS from "ali-oss";
const client = new OSS({
  region: "oss-cn-beijing", // 地域（在创建 Bucket 的时候指定的中心位置），这里可能不知道具体地域怎么填其实就是 oss-cn-中心位置 ，例：region:'oss-cn-chengdu'，chengdu则是创建bucket是指定的位置成都。
  accessKeyId: "LTAI4GEeU3VjmHAWP7GMvhAg", // 阿里云产品的通用id
  accessKeySecret: "URUiu1qeGwBcD6D8Tzd70RFJ0Mrx5s", // 密钥
  bucket: "fangaocloud", // OSS 存储区域名
});
var moment = require("moment"); // require
let oss_date = moment().format("YYYY-MM-DD");
import { nologinForm, submitForm } from "@/api/standard/nologin.js";
import { getOpenId } from "@/api/standard/common.js";
import { Toast } from "vant";
import configopenid from "@/utils/wxconfig.js";
import city from "@/utils/city.js";
import { checkCodeAPI } from "@/api/report.js";
export default {
  name: "noRegistIndex",
  data() {
    return {
      fapi: null,
      rule: [],
      option: {},
      formInfo: {},
      projectInfo: "",
      item_json: [
        {
          value: "",
          options: {
            value: ["", ""],
          },
        },
      ],
      payment_type_ext: localStorage.getItem("payment_type_ext"),
      code: "0714NHkl2vAJd94pTpnl2KYFOA14NHkq",
      paydata: "",
      total_amount: 0,

      checkCodeForm: {
        codeValue: "",
      },

      checkCodeRules: {
        codeValue: [
          { required: true, message: "信息码不能为空", trigger: "blur" },
        ],
      },

      // 当前录取码查询到的信息
      getCurrentCodeInfo: [],
      // 错误信息
      errorMes: "",
    };
  },
  mounted() {
    if (this.payment_type_ext == "alipay") {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src =
        "https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js";
      document.body.appendChild(s);
    }

    this.paydata = JSON.parse(localStorage.getItem("paydata")) || "";
    let projectInfo = JSON.parse(localStorage.getItem("projectInfo"));
    this.payment_type_ext = localStorage.getItem("payment_type_ext");
    this.projectInfo = projectInfo;
    if (this.payment_type_ext == "wxpay" && !this.paydata) {
      this.getUrlParams().then((res) => {
        console.log(res);
        this.result = res;
        this.getOpenId();
      });
    }

    if (this.payment_type_ext == "alipay") {
      this.getUrlParams().then((res) => {
        console.log(res);
        this.getOpenId();
      });
    }
    this.getFormInfo();
  },
  methods: {
    /* 获取页面数据 */
    getUrlParams() {
      return new Promise((resolve) => {
        let url_string = window.location.href; //window.location.href
        let url = new URL(url_string);
        if (this.payment_type_ext == "wxpay") {
          let code = url.searchParams.get("code");
          this.code = code;
        }
        if (this.payment_type_ext == "alipay") {
          let code = url.searchParams.get("auth_code");
          this.code = code;
        }

        resolve();
      });
    },
    /* code获取openid */
    getOpenId() {
      if (configopenid.isline) {
        let info = {
          open_type: this.payment_type_ext,
          code: this.code,
        };
        let data = {
          request: JSON.stringify(info),
        };
        getOpenId(data).then((res) => {
          console.log("微信信息", res);
          if (res.code == 0) {
            this.paydata = res.data;
            localStorage.setItem("paydata", JSON.stringify(res.data));
          }
        });
      } else {
        this.paydata = configopenid.paydata;
        localStorage.setItem("paydata", JSON.stringify(this.paydata));
      }
    },
    /* 请求表单信息 */
    getFormInfo() {
      let info = { project_id: this.projectInfo.project_id };
      let data = {
        request: JSON.stringify(info),
      };
      nologinForm(data).then((res) => {
        console.log("无登录表单", res);
        let data = res.data;
        this.formInfo = data;
        //项目收费规则
        let item_json = data.project_items_json;
        this.item_json = item_json;
        //自定义表单
        let template_json = data.template_json;
        this.rule = template_json.FcDesignerRule;
        let option = template_json.FcDesignerOptions;
        option.global = {
          // 上传组件公共配置
          upload: {
            props: {
              httpRequest: (param) => {
                console.log("httpRequest1", param);
                let fileType = param.file.name.split(".").pop();
                client
                  .put(
                    `fangaopayment/${oss_date}/${new Date().getTime()}_${parseInt(
                      Math.random() * 100000000
                    )}.${fileType}`,
                    param.file
                  )
                  .then((res) => {
                    param.file.url = res.url;
                    param.onSuccess(res, param.file);
                    console.log(res);
                  });
              },
              onSuccess: (res, file) => {
                console.log("上传的图片123", res, file);
                file.url = res.url;
              },
              // 上传成功回调
              // onSuccess: (response, file) => {
              //   console.log("=====", response, file);
              //   file.url = response.data;
              // },
            },
          },
          cascader: {
            props: {
              options: city,
            },
          },
        };
        this.option = option;

        console.log("option --------------- ", option);
      });
    },
    /* 微信支付 */
    onBridgeReady(pay_conf) {
      console.log(pay_conf);
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: pay_conf.appId, //公众号ID，由商户传入
          timeStamp: pay_conf.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: pay_conf.nonceStr, //随机串
          package: pay_conf.package,
          signType: pay_conf.signType, //微信签名方式：
          paySign: pay_conf.sign_new, //微信签名
        },
        (res) => {
          console.log(res);

          if (res.err_msg == "get_brand_wcpay_request:ok") {
            console.log("支付成功");
            this.$router.push("/successPage");
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          } else {
            Toast("支付失败,请重试");
            /* this.$router.go(-1); */
          }
        }
      );
    },
    // 支付宝支付
    alipayBridgeReady(trandNo) {
      if (window.AlipayJSBridge) {
        window.AlipayJSBridge.call(
          "tradePay",
          {
            tradeNO: trandNo,
            // ... 支付参数 ...
          },
          function (result) {
            window.AlipayJSBridge.call("exitApp");

            // 处理支付结果
            console.log("获取支付结果", result);
            // window.close()

            // this.$router.push("/successPage");
          }
        );
      }
    },
    /* 计算 */
    totalSum() {
      let total_amount = this.item_json.reduce((pre, cur) => {
        if (cur.mast_status == "1") {
          cur.value = cur.options[0]["value"];
        }
        let value = cur.value;
        console.log(typeof value);
        if (typeof value == "undefined") {
          value = 0;
        }
        console.log(value);
        if (cur.type == 2) {
          value = value * -1;
        }
        return pre + value * 1;
      }, 0);
      this.total_amount = total_amount.toFixed(2);
    },
    handleChangeFee(val, item) {
      console.log(val, item);
      item.value = val.split("_")[0];
      this.totalSum();
    },
    // 查询录取码
    async checkCodeFn() {
      this.getCurrentCodeInfo = [];
      this.errorMes = "";

      let projectInfo = JSON.parse(localStorage.getItem("projectInfo"));
      let formData = new FormData();
      formData.append(
        "request",
        JSON.stringify({
          project_id: projectInfo.project_id,
          enroll_code: this.checkCodeForm.codeValue,
        })
      );
      let res = await checkCodeAPI(formData);
      if (res.code === 0) {
        this.getCurrentCodeInfo = res.data.list;
      } else {
        this.errorMes = res.msg;
      }
    },

    /* 保存||生成待支付订单 */
    onSubmit() {
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true, // 禁用背景点击
        loadingType: "spinner",
        message: "加载中...",
      });
      console.log("表单提交", this.fApi, this.rule, this.option);
      this.totalSum();
      //删除global
      let option = this.option;
      option.global = {};
      console.log(option);
      let info = {
        project_id: this.projectInfo.project_id,
        total_amount: this.total_amount,
        pay_type: this.projectInfo.pay_type, //this.projectInfo.pay_type,
        payment_type_ext: this.payment_type_ext, //this.payment_type_ext
        is_order: this.projectInfo.is_order,
        openid: this.paydata.openid,
        project_items_json: this.item_json,
        student_submit_json: {
          FcDesignerRule: this.rule,
          FcDesignerOptions: this.option,
        },
        enroll_code: this.checkCodeForm.codeValue,
        localAlipay: 1, // 测试
      };

      this.submitInfo = info;
      console.log(info);
      let data = {
        request: JSON.stringify(info),
      };
      submitForm(data).then((res) => {
        console.log("提交表单", res);
        if (res.code != 0) {
          setTimeout(() => {
            Toast.clear();
          }, 2000);
          return false;
        }
        Toast.clear();

        let pay_conf = res.data.pay_conf ? res.data.pay_conf : null;

        if (pay_conf && pay_conf.qr_code) {
          location.href = pay_conf.qr_code;
          return;
        }

        if (pay_conf && pay_conf.code_url) {
          location.href = pay_conf.code_url;
          return;
        }

        if (
          pay_conf &&
          pay_conf.payType === "zhongxin" &&
          pay_conf.result_code === "SUCCESS" &&
          pay_conf.return_code === "SUCCESS"
        ) {
          location.href = pay_conf.code_url;
          return;
        }

        if (pay_conf && pay_conf.payType === "yinshengv3") {
          location.href = pay_conf.pay_url;
          return;
        }

        if (
          pay_conf &&
          pay_conf.payment_type &&
          pay_conf.payment_type === "zeropay"
        ) {
          Toast("支付完成 ！");
          this.$router.push("/successPage");
          return;
        }

        console.log(pay_conf);
        this.pay_conf = pay_conf;
        if (this.formInfo.verify_type == 2) {
          this.$router.push("/adit");
          return;
        } else if (this.formInfo.verify_type == 3) {
          this.$router.push("/submitSuccess");
          return;
        }
        if (this.formInfo.verify_type == 1 || this.formInfo.verify_type == 4) {
          //微信内支付判断
          if (
            this.payment_type_ext == "wxpay" &&
            this.projectInfo.pay_type == "alipay"
            // this.payment_type_ext == "wxpay" &&
            // this.projectInfo.pay_type != "wxpay" &&
            // this.projectInfo.pay_type != "yinsheng" && this.projectInfo.pay_type != "fuyou" && this.projectInfo.pay_type != "tonglian"
          ) {
            Toast("请使用支付宝支付 ！");
            return false;
          }
          //支付宝内支付判断
          if (
            this.payment_type_ext == "alipay" &&
            this.projectInfo.pay_type == "wxpay"
          ) {
            Toast("请使用微信支付 ！");
            return false;
          }
          //微信内正常支付
          if (
            this.payment_type_ext == "wxpay" &&
            this.projectInfo.pay_type != "alipay"
            // (this.payment_type_ext == "wxpay" && this.projectInfo.pay_type == "wxpay") ||
            // (this.projectInfo.pay_type == "yinsheng" && this.payment_type_ext == "wxpay") ||
            // (this.projectInfo.pay_type == "fuyou" && this.payment_type_ext == "wxpay") ||
            // (this.projectInfo.pay_type == "tonglian" && this.payment_type_ext == "wxpay")
          ) {
            let pay_conf = res.data.pay_conf;
            this.onBridgeReady(pay_conf);
          }
          //支付宝内正常支付
          if (
            this.payment_type_ext == "alipay" &&
            this.projectInfo.pay_type == "alipay"
          ) {
            console.log("支付宝内正常支付");
            location.href = pay_conf.url;
            return;
          }
          if (
            this.payment_type_ext == "alipay" &&
            this.projectInfo.pay_type != "wxpay"
            // (this.projectInfo.pay_type == "yinsheng" ||
            //   this.projectInfo.pay_type == "tonglian" ||
            //   this.projectInfo.pay_type == "fuyou")
          ) {
            console.log("银盛||通联支付宝支付");
            this.alipayBridgeReady(res.data.pay_conf.trade_no);
            return;
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-select {
  /* width: 100%; */
  flex: 1;
}

.content-box {
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .banner-box {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .form-box {
    width: 100%;
    padding: 0.2rem 0.2rem;
    box-sizing: border-box;

    /* 自定义表单 */
    .cost-box,
    .custom-box,
    .sub-box {
      width: 100%;
    }

    .sub-box {
      text-align: center;
    }

    .custom-box,
    .cost-box {
      background: #fff;
      padding: 0.2rem 0.2rem;
      border-radius: 0.2rem;
      box-sizing: border-box;
    }

    /* 缴费 */
    .cost-box {
      margin-top: 0.2rem;

      .input-box {
        display: flex;
        align-items: center;

        .text {
          min-width: 2rem;
        }

        .el-input {
          flex: 1;
        }
      }

      .remark-box {
        margin-top: 0.2rem;
        text-align: center;
        color: red;
      }

      .checkCode {
        .text {
          margin-bottom: 20px;
          color: #4b4b4b;
        }

        .input {
          display: flex;

          .el-input {
            width: 80%;
          }

          .el-button {
            flex: 1;
          }
        }
      }
    }
  }

  /* 提交 */
  .sub-box {
    margin-top: 1rem;
  }
}

/deep/ .el-cascader--medium {
  width: 100% !important;
}
</style>

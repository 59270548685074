<template>
  <div>
    <div class="page">
      <div class="nopay" v-if="status < 4">
        <van-overlay :z-index="10" :show="show" @click="closeOverlayFn">
          <div class="loading"><van-loading color="#1989fa" size="60px" /></div>
        </van-overlay>
        <div class="header"></div>
        <div class="content-box">
          <div class="content">
            <div class="bill_name">{{ billInfo.billName }}</div>
            <div class="total">¥{{ billInfo.total }}</div>
            <div class="billInfo">
              <!-- <div v-for="(item,index) in fee_item" :key="index">
                <span v-if="item.type == 1">{{ item.item_name }}</span>
                <span v-if="item.type == 1">￥{{ item.fee }}</span>
                <span v-if="item.type == 2">{{ item.item_name }}（减）</span>
                <span v-if="item.type == 2">-￥{{ item.fee }}</span>
              </div> -->
              <div class="name">
                <span style="color: #393939;font-size: 14px;">姓名:</span>
                <span style="color:#141414;font-size: 14px;font-weight: bold;">{{ billInfo.name }}</span>
              </div>
              <div class="school">
                <span style="color: #393939;font-size: 14px;">学校：</span>
                <span style="color:#141414;font-size: 14px;font-weight: bold;">{{ billInfo.school }}</span>
              </div>
              <div class="class">
                <span style="color: #393939;font-size: 14px;">班级：</span>
                <span style="color:#141414;font-size: 14px;font-weight: bold;">{{ billInfo.classes }}</span>
              </div>
              <div class="time">
                <span style="color: #393939;font-size: 14px;">截止时间：</span>
                <span style="color:#141414;font-size: 14px;font-weight: bold;">{{ billInfo.time }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="btn" @click="getPayMent"><button>去付款</button></div>
        <div>{{ text }}</div>
      </div>
      <div class="pay" v-else>
        <div class="header"></div>
        <div class="content-box">
          <div class="content">
            <img
              src="../../assets/img/icon_bill_payed.png"
              alt=""
              style="width: 83px"
            />
            <div class="bill_name">{{ billInfo.billName }}</div>
            <div class="total">¥{{ billInfo.total }}</div>
            <div class="billInfo">
              <!-- <div v-for="(item,index) in fee_item" :key="index">
                <span v-if="item.type == 1">{{ item.item_name }}</span>
                <span v-if="item.type == 1">￥{{ item.fee }}</span>
                <span v-if="item.type == 2">{{ item.item_name }}（减）</span>
                <span v-if="item.type == 2">-￥{{ item.fee }}</span>
              </div> -->
              <div class="name">
                <span style="color: #393939;font-size: 14px;">姓名:</span>
                <span style="color:#141414;font-size: 14px;font-weight: bold;">{{ billInfo.name }}</span>
              </div>
              <div class="school">
                <span style="color: #393939;font-size: 14px;">学校：</span>
                <span style="color:#141414;font-size: 14px;font-weight: bold;">{{ billInfo.school }}</span>
              </div>
              <div class="class">
                <span style="color: #393939;font-size: 14px;">班级：</span>
                <span style="color:#141414;font-size: 14px;font-weight: bold;">{{ billInfo.classes }}</span>
              </div>
              <div class="time">
                <span style="color: #393939;font-size: 14px;">截止时间：</span>
                <span style="color:#141414;font-size: 14px;font-weight: bold;">{{ billInfo.time }}</span>
              </div>
            </div>
            <div class="billed">
              <div class="card">
                <span style="color: #393939;font-size: 14px;">交易号:</span>
                <span style="color:#141414;font-size: 14px;font-weight: bold;">{{ billInfo.card }}</span>
              </div>
              <div class="time">
                <span style="color: #393939;font-size: 14px;">支付时间:</span>
                <span style="color:#141414;font-size: 14px;font-weight: bold;">{{ billInfo.paytime }}</span>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="billInfo.shouju_status == 1"
          style="
            margin-top: 20px;
            text-align: center;
            font-size: 16px;
            color: #4581fc;
          "
          @click="lookChange"
        >
          查看收据
        </div>
      </div>
    </div>
    <van-dialog
      v-model="bill_show"
      title=""
      width="100%"
      :showConfirmButton="false"
      closeOnClickOverlay="true"
    >
      <img style="width: 100%; height: 100%" :src="url" ref="imgRef" />
    </van-dialog>
  </div>
</template>

<script>
import { orderDetails, payment, get_look } from "@/api/topay.js";
import { Toast } from "vant";
export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      status: "",
      billInfo: {
        name: "",
        school: "",
        classes: "",
        time: "",
        total: "",
        card: "",
        paytime: "",
        paymode: "",
        billName: "",
        shouju_status: "",
      },
      fee_item: [],
      show: false,
      project_id: "",
      pay_type: "",
      text: "",
      url: "",
      bill_show: false,
    };
  },
  computed: {},
  watch: {},
  created() {
    this.status = this.$route.query.status;
    this.billInfo.billName = this.$route.query.project_name;
  },
  mounted() {
    this.getOrderDetails();

    if (localStorage.getItem("open_type") == "alipay") {
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src =
        "https://gw.alipayobjects.com/as/g/h5-lib/alipayjsapi/3.1.1/alipayjsapi.min.js";
      document.body.appendChild(s);
    }
  },
  methods: {
    closeOverlayFn() {
      this.show = false;
    },
    getPayMent() {
      this.show = true;
      if (
        this.pay_type == "wxpay" &&
        localStorage.getItem("open_type") == "alipay"
      ) {
        Toast("请到微信付款");
        return;
      }
      if (
        this.pay_type == "alipay" &&
        localStorage.getItem("open_type") == "wxpay"
      ) {
        Toast("请到支付宝付款");
        return;
      }
      let info = {
        project_bill_id: this.$route.query.id,
        project_id: this.project_id,
        pay_type: this.pay_type,
        payment_type_ext: localStorage.getItem("open_type"),
        openid: localStorage.getItem("openid"),
        localAlipay: 1, // 测试
      };
      const data = {
        request: JSON.stringify(info),
      };
      payment(data).then((res) => {
        this.show = false;
        if (res.code != 0) {
          Toast(res.msg);
          return;
        }

        let pay_conf = res.data.pay_conf;

        console.log(pay_conf, "支付返回值======");

        if (pay_conf && pay_conf.qr_code) {
          location.href = pay_conf.qr_code;
          return;
        }

        if (
          pay_conf &&
          pay_conf.payType === "zhongxin" &&
          pay_conf.result_code === "SUCCESS" &&
          pay_conf.return_code === "SUCCESS"
        ) {
          location.href = pay_conf.code_url;
          return;
        }

        if (pay_conf && pay_conf.payType === "yinshengv3") {
          location.href = pay_conf.pay_url;
          return;
        }

        let open_type = localStorage.getItem("open_type");
        if (open_type == "alipay" && this.pay_type == "alipay") {
          location.href = pay_conf.url;
          return;
        }

        if (open_type == "wxpay" && this.pay_type == "alipay") {
          Toast("请使用支付宝支付 ！");
          return false;
        }

        if (open_type == "alipay" && this.pay_type == "wxpay") {
          Toast("请使用微信支付 ！");
          return false;
        }

        if (open_type == "wxpay" && this.pay_type != "alipay") {
          this.onBridgeReady(pay_conf);
        }

        if (
          open_type == "alipay" &&
          this.pay_type != "wxpay" &&
          this.pay_type != "alipay"
        ) {
          pay_conf.trade_no && this.alipayBridgeReady(pay_conf.trade_no);
          return;
        }

        // localStorage.getItem('open_type') == 'wxpay'
        //   ? this.onBridgeReady(res.data.pay_conf)
        //   : (window.location.href = res.data.pay_conf.url)
      });
    },
    /* 微信支付 */
    onBridgeReady(pay_conf) {
      console.log(pay_conf);
      window.WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          appId: pay_conf.appId, //公众号ID，由商户传入
          timeStamp: pay_conf.timeStamp, //时间戳，自1970年以来的秒数
          nonceStr: pay_conf.nonceStr, //随机串
          package: pay_conf.package,
          signType: pay_conf.signType, //微信签名方式：
          paySign: pay_conf.sign_new, //微信签名
        },
        (res) => {
          console.log(res);
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            console.log("支付成功");
            this.$router.push("/successPage");
            // 使用以上方式判断前端返回,微信团队郑重提示：
            //res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          } else {
            Toast("支付失败,请重试");
            /* this.$router.go(-1); */
          }
        }
      );
    },
    // 支付宝支付
    alipayBridgeReady(trandNo) {
      if (window.AlipayJSBridge) {
        window.AlipayJSBridge.call(
          "tradePay",
          {
            tradeNO: trandNo,
            // ... 支付参数 ...
          },
          function (result) {
            window.AlipayJSBridge.call("exitApp");

            // 处理支付结果
            console.log("获取支付结果", result);
            // window.close()

            // this.$router.push("/successPage");
          }
        );
      }
    },
    getOrderDetails() {
      let info = {
        project_bill_id: this.$route.query.id,
      };
      const data = {
        request: JSON.stringify(info),
      };
      orderDetails(data).then((res) => {
        if (res.code == 0) {
          this.billInfo.name = res.data.info.stu_name;
          this.billInfo.school = res.data.info.school_name;
          this.billInfo.classes = res.data.info.classes;
          this.billInfo.time = res.data.info.bill_end_time;
          this.billInfo.total = res.data.info.total_money;
          this.billInfo.card = res.data.info.payment_Info.pay_type_sn;
          this.billInfo.paytime = res.data.info.payment_Info.payment_date;
          this.project_id = res.data.info.payment_Info.project_id;
          this.pay_type = res.data.info.pay_type;
          this.billInfo.shouju_status = res.data.info.shouju_status;
          // this.billInfo.billName = res.data.info.project_name
          this.fee_item = res.data.fee_item;
        } else {
          Toast(res.msg);
        }
      });
    },
    lookChange() {
      const data = {
        request: JSON.stringify({
          project_bill_id: this.$route.query.id,
        }),
      };
      get_look(data).then((res) => {
        if (res.code != 0) {
          Toast(res.msg);
          return;
        }
        this.url = res.data.bill_url;
        this.bill_show = true;
      });
    },
    // downloadUrl() {
    //   console.log(111111, this.$refs.imgRef.src, this.url);
    //   const link = document.createElement("a");
    //   link.href = this.$refs.imgRef.src;
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // },
  },
};
</script>

<style lang="less" scoped>
* {
  box-sizing: border-box;
}
.loading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page {
  width: 100%;
  .nopay {
    .header {
      width: 100%;
      height: 213px;
      background: linear-gradient(149.93deg, #74c9ff 0%, #438efd 100%);
    }
    .content-box {
      padding: 0 15px;
      .content {
        width: 100%;
        height: 290px;
        background-color: #ffffff;
        margin-top: -150px;
        border-radius: 10px;
        padding: 15px 20px;
        .bill_name {
          margin-top: 10px;
          text-align: center;
          font-size: 26px;
          font-weight: bold;
          color:#393939
        }
        .total {
          font-size: 18px;
          text-align: center;
          margin-top: 15px;
          color: #EB5B4F;
          font-weight: 600;
        }
        .billInfo {
          margin-top: 30px;
          width: 100%;
          height: 130px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
    .btn {
      padding: 0 20px;

      button {
        margin-top: 30px;
        width: 100%;
        height: 40px;
        background: linear-gradient(90deg, #4492fe 0%, #33d9ff 100%);
        border-radius: 20px;
        color: #ffffff;
        text-align: center;
        line-height: 40px;
        border: none;
      }
    }
  }
  .pay {
    .header {
      width: 100%;
      height: 213px;
      background: linear-gradient(149.93deg, #74c9ff 0%, #438efd 100%);

    }
    .content-box {
      padding: 0 15px;
      .content {
        width: 100%;
        background-color: #ffffff;
        margin-top: -150px;
        border-radius: 10px;
        padding: 15px 20px;
        position: relative;
        img {
          position: absolute;
          top: 0;
          right: 0;
        }
        .bill_name {
          margin-top: 10px;
          text-align: center;
          font-size: 26px;
          font-weight: bold;
          color:#393939
        }
        .total {
          font-size: 18px;
          text-align: center;
          margin-top: 15px;
          color: #EB5B4F;
          font-weight: 600;
        }
        .billInfo {
          margin-top: 30px;
          width: 100%;
          height: 130px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding-bottom: 15px;
          border-bottom: 2px solid #f2f2f2;
          div {
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
        .billed {
          margin-top: 15px;
          div {
            height: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          }
        }
      }
    }
  }
}
</style>

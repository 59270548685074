
<template>
  <div class="page">
    <div class="head_box" v-if="student_data.length > 1">
      <div class="head_left">
        <div class="head_left_top">
          <div style="display: flex; align-items: center">
            <img style="margin-right: 6px" src="../../assets/img/head2.png" />
            <div class="title">{{ username }}</div>
          </div>
          <div
            style="display: flex; align-items: center"
            @click="unbindUserChange(stu_id_no)"
          >
            <div style="padding-right: 5px; color: #fff; font-size: 12px">
              解绑
            </div>
            <img src="../../assets/img/icon_right.png" />
          </div>
        </div>
        <div class="head_left_school">
          <div class="school_txt" style="width:400px">
            学校:<span>{{ school }}</span>
          </div>
          <div class="school_txt">
            班级: <span>{{ classes }}</span>
          </div>
        </div>
      </div>
      <div class="center" @click="selectUserChange">
        <img src="../../assets/img/head3.png" />
        <div style="padding-top: 5px">{{ list[0].stu_name }}</div>
        <div style="padding-top: 10px">切换</div>
      </div>
      <div class="right" @click="addUserChange">
        <img src="../../assets/img/icon_add.png" />
        <div>添加</div>
      </div>
    </div>
    <div class="head_box" v-else>
      <div class="left">
        <div style="margin-left: 20px; margin-right: 10px">
          <img class="left_img" src="../../assets/img/head1.png" />
        </div>
        <div style="padding-top: 27px; width: 60%">
          <div class="left_box" @click="unbindUserChange(stu_id_no)">
            <div class="title">{{ username }}</div>
            <div class="left_box">
              <div style="padding-right: 5px">解绑</div>
              <img src="../../assets/img/icon_right.png" />
            </div>
          </div>
          <div class="left_box" style="padding-top: 12px">
            <div class="school_txt" style="width:400px">
              学校:<span>{{ school }}</span>
            </div>
            <div class="school_txt">
              班级: <span>{{ classes }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="right" @click="addUserChange">
        <img src="../../assets/img/icon_add.png" />
        <div>添加</div>
      </div>
    </div>
    <div
      class="content"
      v-for="item in wait_pay_list"
      :key="item.project_bill_id"
      @click="gopayDetails(item)"
    >
      <div class="tip_box" v-if="item.status!=4" style="color: #ff2f1e; background-color: #ffe1de">
        未付款
      </div>
      <div class="tip_box" v-if="item.status==4" style="color: #12ac0d; background-color: #f0ffde">
        已付款
      </div>
      <div class="title_name">{{ item.project_name }}</div>
      <div
        class="info"
        style="border-bottom: 0.8px solid #d1d1d1; padding-bottom: 15px"
      >
        <div>姓名：{{ item.stu_name }}</div>
        <div style="padding-left: 28px">学校：{{ item.school_name }}</div>
      </div>
      <div class="info" style="justify-content: space-between">
        <div class="money">￥{{ item.total_money }}</div>
        <img style="margin-top: 10px" src="../../assets/img/right.png" />
      </div>
    </div>
    <div
      class="content"
      v-for="item in paid_list"
      :key="item.project_bill_id"
      @click="gopayDetails(item)"
    >
      <div class="tip_box" v-if="item.status!=4" style="color: #ff2f1e; background-color: #ffe1de">
        未付款
      </div>
      <div class="tip_box" v-if="item.status==4" style="color: #12ac0d; background-color: #f0ffde">
        已付款
      </div>
      <div class="title_name">{{ item.project_name }}</div>
      <div
        class="info"
        style="border-bottom: 0.8px solid #d1d1d1; padding-bottom: 15px"
      >
        <div>姓名：{{ item.stu_name }}</div>
        <div style="padding-left: 28px">学校：{{ item.school_name }}</div>
      </div>
      <div class="info" style="justify-content: space-between">
        <div class="money">￥{{ item.total_money }}</div>
        <img style="margin-top: 10px" src="../../assets/img/right.png" />
      </div>
    </div>
    <van-empty
      description="暂无数据"
      v-if="paid_list.length == 0 && wait_pay_list.length == 0"
    />
    <van-popup v-model="show" round position="bottom">
      <van-picker
        show-toolbar
        :columns="columns"
        @cancel="show = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { orderList } from "@/api/topay.js";
import { Toast, Dialog } from "vant";

export default {
  name: "",
  props: {},
  components: {},
  data() {
    return {
      username: JSON.parse(localStorage.getItem("header")).stu_name,
      stu_id_no: JSON.parse(localStorage.getItem("header")).stu_id_no,
      school: "",
      classes: JSON.parse(localStorage.getItem("header")).classes,
      wait_pay_list: [],
      paid_list: [],
      student_data: [],
      show: false,
      columns: [],
      list: [],
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.student_data = JSON.parse(localStorage.getItem("student_list"));
    this.list = this.student_data.filter((element) => !this.isEven(element));
    console.log(this.list, "this.list");
    this.getOrderList();
  },
  methods: {
    isEven(element) {
      return (
        element.stu_id_no ==
        JSON.parse(localStorage.getItem("header")).stu_id_no
      );
    },
    gopayDetails(item) {
      this.$router.push({
        path: "/payDetails",
        query: {
          id: item.project_bill_id,
          status: item.status,
          project_name: item.project_name,
        },
      });
    },
    getOrderList() {
      let info = {
        number: JSON.parse(localStorage.getItem("header")).stu_id_no,
        name: JSON.parse(localStorage.getItem("header")).stu_name,
        school_id: JSON.parse(localStorage.getItem("projectInfo")).school_id,
      };
      const data = {
        request: JSON.stringify(info),
      };
      orderList(data).then((res) => {
        if (res.code == 0) {
          this.paid_list = res.data.paid_list;
          this.wait_pay_list = res.data.wait_pay_list;
          if (this.wait_pay_list.length != 0) {
            this.school = this.wait_pay_list[0].school_name;
          } else if (this.paid_list.length != 0) {
            this.school = this.paid_list[0].school_name;
          } else {
            this.school = JSON.parse(
              localStorage.getItem("header")
            ).school_name;
          }
        } else {
          Toast(res.msg);
        }
      });
    },
    addUserChange() {
      this.$router.push({
        path: "/h5logincopy",
        query: {
          project_hash: localStorage.getItem("project_hash"),
          show: true,
        },
      });
    },
    unbindUserChange(val) {
      Dialog.confirm({
        title: "温馨提示",
        message: "是否解绑该学生",
      }).then(() => {
        this.student_data.forEach((item, index) => {
          if (item.stu_id_no == val) {
            this.student_data.splice(index, 1);
          }
        });
        if (this.student_data.length == 0) {
          this.$router.push({
            path: "/h5logincopy",
            query: {
              project_hash: localStorage.getItem("project_hash"),
              show: true,
            },
          });
          localStorage.removeItem("student_list");
          localStorage.removeItem("header");
          return;
        }
        localStorage.setItem("student_list", JSON.stringify(this.student_data));
        localStorage.setItem("header", JSON.stringify(this.student_data[0]));
        this.username = JSON.parse(localStorage.getItem("header")).stu_name;
        this.stu_id_no = JSON.parse(localStorage.getItem("header")).stu_id_no;
        this.school = JSON.parse(localStorage.getItem("header")).school_name;
        this.classes = JSON.parse(localStorage.getItem("header")).classes;
        this.getOrderList();
      });
    },
    selectUserChange() {
      if (this.student_data.length >= 3) {
        this.show = true;
        this.columns = [];
        this.student_data.forEach((item) => {
          this.columns.push({ text: item.stu_name, value: item.stu_id_no });
        });
      } else {
        localStorage.setItem("header", JSON.stringify(this.list[0]));
        this.username = JSON.parse(localStorage.getItem("header")).stu_name;
        this.stu_id_no = JSON.parse(localStorage.getItem("header")).stu_id_no;
        this.school = JSON.parse(localStorage.getItem("header")).school_name;
        this.classes = JSON.parse(localStorage.getItem("header")).classes;
        this.list = this.student_data.filter(
          (element) => !this.isEven(element)
        );
        this.getOrderList();
      }
    },
    onConfirm(val) {
      const index = this.student_data.findIndex(function (element) {
        return (
          element.stu_id_no ==
          JSON.parse(localStorage.getItem("header")).stu_id_no
        );
      });
      this.index = index;
      const number = val.value;
      this.student_data.forEach((item) => {
        if (item.stu_id_no == number) {
          localStorage.setItem("header", JSON.stringify(item));
          this.list = this.student_data.filter(
            (element) => !this.isEven(element)
          );
          this.username = JSON.parse(localStorage.getItem("header")).stu_name;
          this.stu_id_no = JSON.parse(localStorage.getItem("header")).stu_id_no;
          this.school = JSON.parse(localStorage.getItem("header")).school_name;
          this.classes = JSON.parse(localStorage.getItem("header")).classes;
          this.getOrderList();
        }
      });

      this.show = false;
    },
  },
};
</script>

<style lang="less" scoped>
.page {
  width: 100%;
  background: #fff;
  height: 100vh;
}
.head_box {
  display: flex;
  align-items: center;
  padding-top: 20px;
  margin: 0 20px;
  justify-content: space-around;
}
.head_left {
  background-image: url("../../assets/img/beg_two.png");
  background-size: 100% 100%;
  width: 213px;
  height: 117px;
}
.head_left_top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 20px;
  padding-top: 15px;
}
.head_left_school {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 12px 20px;
  color: #fff;
  font-size: 12px;
}
.center {
  width: 66px;
  height: 103px;
  border-radius: 14px;
  background: linear-gradient(180deg, #8573fd 0%, #9483fc 100%);
  box-shadow: 1px 7px 20px #b5d2fe;
  margin-bottom: 13px;
  color: #fff;
  text-align: center;
  font-size: 12px;
  font-weight: bold;

  img {
    width: 18px;
    height: 18px;
    margin-top: 20px;
  }
}
.left {
  background-image: url("../../assets/img/beg_two.png");
  background-size: 100% 100%;
  width: 289px;
  height: 116px;
  display: flex;
  .left_img {
    width: 52px;
    height: 52px;
    margin: 26px auto;
  }
}

.title {
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #fff;
}
.left_box {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  justify-content: space-between;
}
.right {
  width: 36px;
  height: 103px;
  border-radius: 12px;
  background: linear-gradient(180deg, #2bbed0 0%, #6edff5 100%);
  box-shadow: 1px 7px 20px #b5d2fe;
  margin-bottom: 13px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  margin-left: 5px;
  img {
    width: 18px;
    height: 18px;
    margin: 50% auto;
  }
}
.content {
  margin: 12px 20px;
  height: 124px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 3px 14px #95bcf666;
  position: relative;
}
.tip_box {
  width: 60px;
  height: 21px;
  line-height: 21px;
  border-radius: 0 9px 0 11px;
  position: absolute;
  right: 0;
  font-weight: 400;
  font-size: 12px;
  text-align: center;
}
.title_name {
  font-weight: bold;
  font-size: 16px;
  color: #2d2d2d;
  padding: 13px 18px;
}
.info {
  display: flex;
  align-items: center;
  margin: 5px 18px;
}
.money {
  font-weight: bold;
  font-size: 16px;
  text-align: left;
  color: #ff210e;
  padding-top: 10px;
}
.school_txt {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
